import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import {environment} from '../../../environments/environment';
import { Observable, Subject } from 'rxjs';
import {Message} from '../../models/conversation';
@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  private  connection: any = new signalR.HubConnectionBuilder().withUrl(environment.BASE_URL + '/hub/conversation')
    .configureLogging(signalR.LogLevel.Information)
    .build();

  private receiveMessage = new Subject<Message>();

  constructor() {
    this.connection.onclose(async () => {
      await this.start();
    });
    this.connection.on('MessageReceived', (message) => {
      this.receiveMessage.next(message);
    });
    this.start();
  }

  public async start(): Promise<void> {
    try {
      await this.connection.start();
    } catch (err) {
      setTimeout(() => this.start(), 5000);
    }
  }

  public onReceiveMessage(): Observable<Message> {
    return this.receiveMessage.asObservable();
  }

}

<div class="custom-container py-5">
  <div class="content py-5">
    <div class="row">
      <div class="col-2 offset-1">
        <div class="text-center">
          <button routerLink="/premium/chat-list" class="round-button w-100">
          <span class="btn-text">
            CRÉEZ VOTRE PACK DE 5
          </span>
          </button>
          <div class="py-5">
            <img class="love-basket" src="assets/SVG/love-basket.png" alt="">
          </div>
          <div class="pb-3">
            <p class="white-text">Vous avez deux personnes Dans votre panier
            </p>
          </div>
          <div class="pb-5">
            <p class="white-text small">Il vous reste trois autres pour construire votre pack</p>
          </div>
          <div>
            <p class="white-text large">
              VOUS AVEZ 8 ÂMES SOEURS POUR CHATER ET DECOUVRIR VOTRE RELATION
            </p>
          </div>
        </div>
      </div>
      <div class="col-2 offset-3 d-flex flex-column justify-content-center">
        <div *ngFor="let i of [0, 1, 2, 3]" class="d-flex align-items-center py-2">
          <mat-checkbox [disableRipple]="true">
            <div class="ml-2 image-container">
              <img src="assets/images/adam.jpeg" alt="">
            </div>
          </mat-checkbox>
        </div>
      </div>
      <div class="col-2 d-flex flex-column justify-content-center">
        <div *ngFor="let i of [0, 1, 2, 3]" class="d-flex align-items-center py-2">
          <mat-checkbox [labelPosition]="'before'" [disableRipple]="true">
            <div class="mr-2 image-container">
              <img src="assets/images/adam.jpeg" alt="">
            </div>
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div class="container-fluid pt-4">
      <div class="row">
        <div class="col-1 carousel-nav-alt justify-content-end">
          <button (click)="owlCar.prev()" class="flat-btn">
          <span class="arrow-left">

          </span>
          </button>
        </div>
        <div class="col-10 d-flex flex-column justify-content-center">
          <div class="alt-carousel">
            <owl-carousel-o [options]="customOptions" #owlCar>
              <ng-template *ngFor="let item of [0, 1, 2, 3, 4, 5, 6, 7 ,8, 9]" carouselSlide>
                <div class="d-flex justify-content-center">
                  <div class="carousel-element">
                    <img src="assets/images/adam.jpeg" alt="">
                  </div>
                </div>
              </ng-template>
            </owl-carousel-o>
          </div>
        </div>
        <div class="col-1 carousel-nav-alt">
          <button (click)="owlCar.next()" class="flat-btn">
          <span class="arrow-right">

          </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import {Conversation, Message} from '../../../models/conversation';
import {MainService} from '../../../core/services/main.service';
import {SignalrService} from '../../../core/services/signalr.service';
import {CredentialsService} from '../../../core/authentication/credentials.service';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss']
})
export class ChatListComponent implements OnInit {


  conversations: Conversation[];

  constructor(private apiService: MainService,
              private signalrService: SignalrService,
              private credentialsService: CredentialsService) { }

  ngOnInit(): void {
    this.getData();
    this.signalrService.onReceiveMessage().subscribe((m: Message) => {
      if (m.receiverId.toString() === this.credentialsService.credentials?.user.id.toString()) {
        this.getData();
      }
    });
    this.apiService.onChangeMessages().subscribe(d => {
      this.getData();
    });
  }

  getData(): void {
    this.apiService.getConversations().then(d => {
      this.conversations = d;
    });
  }

}

import { Component, OnInit } from '@angular/core';
import {ICreateOrderRequest, IPayPalConfig} from 'ngx-paypal';
import {Payment} from '../../../models/payment';
import {MatDialogRef} from '@angular/material/dialog';
import {MainService} from '../../../core/services/main.service';
import Swal from 'sweetalert2';
import {PaymentModalComponent} from '../payment-modal/payment-modal.component';
import {environment} from '../../../../environments/environment';
import {User} from '../../../models/user';
import {CredentialsService} from '../../../core/authentication/credentials.service';

@Component({
  selector: 'app-add-credits-modal',
  templateUrl: './add-credits-modal.component.html',
  styleUrls: ['./add-credits-modal.component.scss']
})
export class AddCreditsModalComponent implements OnInit {

  /* public payPalOneCreditConfig?: IPayPalConfig;
  public payPalFiveCreditsConfig?: IPayPalConfig; */
  public payPalMensuelConfig?: IPayPalConfig;
  public payPalBronzeConfig?: IPayPalConfig;
  public payPalPlatiniumConfig?: IPayPalConfig;
  public payment: Payment;
  public paymentConfiguration: any;
  public showPayment = false;
  public selectedOffer = 0;

  constructor(private dialogRef: MatDialogRef<AddCreditsModalComponent>,
              private mainService: MainService,
              private credentialsService: CredentialsService) {
    this.initConfig();

  }

  ngOnInit(): void {
  }

  selectOffer(offer): void {
    this.selectedOffer = offer;
  }

  initPayment(name: string, value: number, paymentItemType: number): IPayPalConfig {
    return {
      currency: 'EUR',
      clientId: 'AQKIBdc76_6zloGGrAlTlHAKn63p0p7LsbHSdrFtedlvLhIvAJIIjHw7YIDk-JTWHI_eEFc7An5Mm_I_',
      createOrderOnClient: (data) => this.createOrder(this, name, value),
      style: {
        layout: 'vertical',
        size: 'medium',
        shape: 'pill',
        label: 'checkout',
      },
      onApprove: (data, actions) => {
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
        });

      },
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        Swal.fire({
          title: 'Bravo !',
          text: 'Paiement validé avec succès',
          icon: 'success',
          confirmButtonText: 'D\'accord'
        });
        this.validatePayment();
      },
      onCancel: (data, actions) => {
        Swal.fire({
          title: 'Erreur!',
          text: 'Vous avez annulé le paiement',
          icon: 'error',
          confirmButtonText: 'D\'accord'
        });
      },
      onError: err => {
        Swal.fire({
          title: 'Erreur!',
          text: 'Il y\'a un probléme lors du paiement',
          icon: 'error',
          confirmButtonText: 'D\'accord'
        });
      },
      onClick: (data, actions) => {
        this.createPayment(paymentItemType);
      },
    };
  }

  private initConfig(): void {
    console.log('Init config');
    this.mainService.getPaymentConfiguration().then(d => {
      this.paymentConfiguration = d;
      /* this.payPalOneCreditConfig = this.initPayment('Born to meet one credit', this.paymentConfiguration.oneCredit, 1);
      this.payPalFiveCreditsConfig = this.initPayment('Born to meet five Credits', this.paymentConfiguration.packFiveCredits, 2); */
      this.payPalMensuelConfig = this.initPayment('Born to meet Mensuel', this.paymentConfiguration.mensuel, 1);
      this.payPalBronzeConfig = this.initPayment('Born to meet Bronze', this.paymentConfiguration.bronze, 2);
      this.payPalPlatiniumConfig = this.initPayment('Born to meet Platinium', this.paymentConfiguration.platinium, 3);
      this.showPayment = true;
    });
  }

  private createOrder(self, name: string, value: number): ICreateOrderRequest{
    return {
      intent: 'CAPTURE',
      purchase_units: [{
        amount: {
          currency_code: 'EUR',
          value: value.toString(),
          breakdown: {
            item_total: {
              currency_code: 'EUR',
              value: value.toString()
            }
          }
        },
        items: [{
          name,
          quantity: '1',
          category: 'DIGITAL_GOODS',
          unit_amount: {
            currency_code: 'EUR',
            value: value.toString(),
          },
        }]
      }]
    };
  }

  createPayment(paymentItemType: number): void {
    this.mainService.createPayment({
      paymentItems: [
        {
          paymentItemType,
          quantity: 1
        }
      ]
    }).then(d => {
      this.payment = d;
    });
  }

  validatePayment(): void {
    if (this.payment) {
      this.mainService.validatePayment(this.payment.transactionId).then(d => {
        this.payment = d;
        this.mainService.getConnectedUser().then(d => {
          this.saveUser(d);
        }).catch(e => {
          this.credentialsService.setCredentials();
        });
      });
    }
  }

  saveUser(user: User): void {
    this.credentialsService.setCredentials({
      user,
      token: user.token
    }, true);
    this.dialogRef.close(true);
  }

}

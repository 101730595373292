<section class="container-fluid login-section login-page">
  <div class="carousel-items">
    <owl-carousel-o [options]="customOptions">
      <ng-template *ngFor="let item of images" carouselSlide>
        <img [src]="item"/>
      </ng-template>
    </owl-carousel-o>
  </div>
  <div class="row full-height">
    <div class="col position-relative d-flex flex-column justify-content-center">
      <!-- S<img class="subscribe-banner" src="../../../../assets/images/seconnecter.png" alt=""> -->
      <div class="subscribe-text">
        <p>
          VIVEZ UNE RELATION COMPATIBLE AVEC <br/>BORN TO MEET
        </p>
      </div>
    </div>
    <div class="col-5" >
      <div class="login-container">
        <h2 class="login-title">Se connecter</h2>
        <form class="login-form" [formGroup]="form">
          <div class="row pt-3">
            <div class="col-12">
              <div class="py-1">
                <input formControlName="email" type="email" class="form-control" placeholder="MAIL">
              </div>
              <div class="py-1">
                <input formControlName="password" type="password" class="form-control" placeholder="MOT DE PASSE">
              </div>
              <div class="py-1 d-none">
                <button class="facebook-button">
                  SE CONNECTER AVEC FACEBOOK
                </button>
              </div>
              <div class="py-1 d-none">
                <button class="google-button">
                  SE CONNECTER AVEC GOOGLE
                </button>
              </div>
            </div>
          </div>
          <div class="d-flex pt-3 justify-content-end">
            <button class="primary-button" (click)="sumbit()">
              <span class="btn-text">Valider</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

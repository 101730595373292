import { Component } from '@angular/core';
import {MainService} from './core/services/main.service';
import {CredentialsService} from './core/authentication/credentials.service';
import {User} from './models/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'born2meet';

  constructor(private mainService: MainService, private credentialsService: CredentialsService) {
    if (this.credentialsService.isAuthenticated()) {
      this.mainService.getConnectedUser().then(d => {
        const user: User = d;
        this.credentialsService.setCredentials({
          user,
          token: user.token
        }, true);
      }).catch(e => {
        this.credentialsService.setCredentials();
      });
    }
  }

}

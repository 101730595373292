import { Component, OnInit } from '@angular/core';
import {MainService} from '../../core/services/main.service';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {User} from '../../models/user';
import {CredentialsService} from '../../core/authentication/credentials.service';

@Component({
  selector: 'app-list-ames',
  templateUrl: './list-ames.component.html',
  styleUrls: ['./list-ames.component.scss']
})
export class ListAmesComponent implements OnInit {

  user: User;

  constructor(private api: MainService, public credentialsService: CredentialsService) {
    this.user = this.credentialsService.credentials?.user;
    this.credentialsService.userChangedObservable.subscribe(d => {
      this.user = this.credentialsService.credentials?.user;
    });
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: false,
    autoplay: true,
  };

  ngOnInit(): void {

  }

}

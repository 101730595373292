import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-basket-validate',
  templateUrl: './basket-validate.component.html',
  styleUrls: ['./basket-validate.component.scss']
})
export class BasketValidateComponent implements OnInit {

  list: []
  constructor() { }

  ngOnInit(): void {
  }

}

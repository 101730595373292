import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import {CredentialsService} from '../../../core/authentication/credentials.service';
import {SearchUserItem, User} from '../../../models/user';
import {MainService} from '../../../core/services/main.service';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss']
})
export class BasketComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      },
      1200: {
        items: 6
      }
    },
    nav: false
  };

  list: SearchUserItem[];
  selectedUsers: SearchUserItem[] = [];

  constructor(public credentialsService: CredentialsService, private api: MainService, private router: Router) {
  }

  ngOnInit(): void {
    this.api.searchUsers().then(d => {
      this.list = d;
    });
  }

  selectUser(item: SearchUserItem): void {
    const index = this.selectedUsers.indexOf(item);
    if (index >= 0) {
      this.selectedUsers.splice(index, 1);
    } else {
      this.selectedUsers.push(item);
    }
  }

  isUserSelected(item: SearchUserItem): boolean {
    return this.selectedUsers.indexOf(item) >= 0;
  }

  async afterValidateBasket(): Promise<void> {
    const user = this.credentialsService.credentials?.user;
    if (user.state != 2) {
      Swal.fire({
        title: 'Erreur!',
        text: 'Vous n\'avez pas un abonnement actif, Merci de s\'abonner',
        icon: 'error',
        confirmButtonText: 'D\'accord'
      });
    } else {
      for (let i = 0; i < this.selectedUsers.length; i++) {
        await this.api.addUser(this.selectedUsers[i].user.id);
      }
      this.api.getConnectedUser().then(d => {
        const connectedUser: User = d;
        this.credentialsService.setCredentials({
          user: connectedUser,
          token: connectedUser.token
        }, true);
        Swal.fire({
          title: 'Bravo !',
          text: 'Âmes soeurs ajoutés avec succès',
          icon: 'success',
          confirmButtonText: 'D\'accord'
        });
        this.router.navigate(['/premium/profile']);
      }).catch(e => {
        this.credentialsService.setCredentials();
      });
    }
  }

  validateBasket(): void {
    const self = this;
    Swal.fire({
      title: 'Vous êtes sûr ?',
      text: 'Merci de valider votre panier',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Valider',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.isConfirmed) {
        this.afterValidateBasket();
      }
    });
  }

}

<div class="container-fluid video-background">
  <video autoplay="" loop="" muted="" playsinline="" class="page-left__media" data-id="2">
    <source src="assets/video/BTM_V5.mp4" type="video/mp4">
  </video>
  <div class="container py-5">
    <div class="row">
      <div class="col-2">
        <div class="chat-list d-flex flex-column text-wrap">
          <div class="row">
            <h1 class="title">ÂMES SOEURS</h1>
          </div>
          <div class="chat-list-content">
            <div class="container-fluid w-100">
              <div class="row pointer-on-hover" *ngFor="let item of conversations" [routerLink]="['/premium/chat-list', item.user.id]" routerLinkActive="active">
                <div class="col text-center">
                  <div class="chat-element">
                    <img class="image" [src]="item.user.profileImage || 'assets/images/default-user.jpg'" alt="">
                    <span *ngIf="item.unReadedMessages > 0" class="circle-badge">{{item.unReadedMessages}}</span>
                  </div>
                  <p class="text-white font-weight-bold font">{{item.user.firstName.toUpperCase()}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<div class="container py-3">
  <div class="row">
    <div class="col-auto">
      <p class="advert-text">
        <span><strong>BORN TO MEET</strong> looks for your soulmate</span><br>
        <span class="spacing">among 11700 compatibilities</span>
      </p>
    </div>
    <div class="col">
      <img class="w-25 m-auto" style="display: block;" src="assets/SVG/born2meet.png" alt="">
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row my-5">
    <div class="container d-flex align-center justify-content-between">
      <div class="user-item">
        <img *ngIf="user.profileImage" [src]="user.profileImage"/>
        <img *ngIf="!user.profileImage" [src]="'assets/SVG/upload-avatar.png'"/>
        <div class="">
          <h5 class="d-block">{{user.firstName}} {{user.lastName}}</h5>
          <span *ngIf="user.state == 0">Pas d'abonnement actif</span>
          <span *ngIf="user.state == 1">Abonnement expiré le {{user.packEndDate | date}}</span>
          <span *ngIf="user.state == 2">Abonnement valide jusqu'au {{user.packEndDate | date}}</span>
        </div>
      </div>
      <div class="text-center d-flex justify-content-center mt-3">
        <div class="d-flex mr-3" style="flex-direction: column">
          <!-- <button class="premium-btn d-block mb-3" (click)="addCredits()">
            <span class="btn-text">Ajouter des crédits</span>
          </button> -->
          <button *ngIf="user.state != 2"class="premium-btn d-block mb-3" (click)="subscribe()">
            <span class="btn-text">S'abonner</span>
          </button>
          <button *ngIf="user.state == 2"class="premium-btn d-block mb-3" disabled>
            <span class="btn-text">S'abonner</span>
          </button>
          <button class="premium-btn d-block mb-3" routerLink="/premium/basket">
            <span class="btn-text">Ajouter des âmes</span>
          </button>
        </div>
        <div class="d-flex" style="flex-direction: column">
          <button class="premium-btn mb-3" routerLink="/premium/profile/edit">
            <span class="btn-text">Modifier mon profil</span>
          </button>
          <button class="premium-btn mb-3" (click)="logout()">
            <span class="btn-text">Déconnexion</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-list-ames></app-list-ames>

import { Component, OnInit } from '@angular/core';
import {MainService} from '../../core/services/main.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-discover-us',
  templateUrl: './discover-us.component.html',
  styleUrls: ['./discover-us.component.scss']
})
export class DiscoverUsComponent implements OnInit {

  videos: any[];
  videosIndex = [0, 1, 2];
  selectedIndex = 0;

  constructor(private api: MainService, protected sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.api.getVideos().then(d => {
      this.videos = d.map(v => {
        v.link = this.sanitizer.bypassSecurityTrustResourceUrl(v.link);
        return v;
      });
      this.loppVideos();
    });
  }

  loppVideos(): void {
    setInterval(d => {
      this.selectedIndex = (this.selectedIndex + 1) % (this.videos.length);
    }, 15000);
  }

}

<mat-dialog-content>
  <p class="payment-text text-center">
    Choisir la formule qui vous convient
  </p>
 <div style="height: 400px;">
    <!-- <img class="top-logo" alt="born2meet" src="assets/SVG/born2meet.svg"> -->
    <div class=" row justify-content-between" *ngIf="showPayment">
      <div class="col-4 p-0 text-center">
        <div class="wide-container">
          <div class="pointer-on-hover" (click)="selectOffer(1)">
            <p>Mensuel</p>
            <p *ngIf="selectedOffer != 1" class="description-text">Abonnement d'un mois<br/><br/>
              -Sélection illimitée d'âmes sœurs dans le panier d'amour<br/>
-De nouvelles âmes sœurs proposées<br/>
-Rester présent pendant le mois dans le réseautage du panier d'’'amour.<br/>
-Accès au panier de match pour chat et consultation du récit de la relation.
            </p>
            <button class="premium-btn mt-3 mb-5">
              <span class="btn-text">{{paymentConfiguration.mensuel}} euro</span>
            </button>
          </div>
          <ngx-paypal *ngIf="selectedOffer == 1" [config]="payPalMensuelConfig"></ngx-paypal>
        </div>
      </div>
      <div class="col-4 p-0 text-center">
        <div class="wide-container">
          <div class="pointer-on-hover" (click)="selectOffer(2)">
            <p>Bronze</p>
            <p *ngIf="selectedOffer != 2" class="description-text">Abonnement de 3 mois               <br/><br/>
              -Tous ce que tu aime de l’abonnement mensuel<br/>
-1 Boost gratuit par mois<br/><br/><br/><br/><br/>
            </p>
            <button class="premium-btn mt-3 mb-5">
              <span class="btn-text">{{paymentConfiguration.bronze}} euro</span>
            </button>
          </div>
          <ngx-paypal  *ngIf="selectedOffer == 2" [config]="payPalBronzeConfig"></ngx-paypal>
        </div>
      </div>
      <div class="col-4 p-0 text-center">
        <div class="wide-container">
          <div class="pointer-on-hover" (click)="selectOffer(3)">
            <p>Platinium</p>
            <p *ngIf="selectedOffer != 3" class="description-text">Abonnement de 6 mois<br/><br/>
              -Tous ce que tu aime de l’abonnement Bronze<br/>
-1 message pour chaque âme sœur du panier d’amour avant match<br/><br/><br/><br/>
            </p>
            <button class="premium-btn mt-3 mb-5">
              <span class="btn-text">{{paymentConfiguration.platinium}} euro</span>
            </button>
          </div>
          <ngx-paypal  *ngIf="selectedOffer == 3" [config]="payPalPlatiniumConfig"></ngx-paypal>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="d-flex justify-content-end pt-4">
    <button mat-dialog-close class="close-btn">
      No thanks
    </button>
  </div> -->
</mat-dialog-content>

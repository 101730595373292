import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { PaymentModalComponent } from '../../modals/payment-modal/payment-modal.component';
import { DynamicTooltipService, keyword } from '../../../core/services/dynamic-tooltip.service';

@Component({
  selector: 'app-truth',
  templateUrl: './truth.component.html',
  styleUrls: ['./truth.component.scss']
})
export class TruthComponent implements OnInit {

  moment = moment;

  dates: {date1: string, date2: string, coupleType: number};
  result: string;
  text: string;
  shortText: string;

  showLong: boolean;
  dynamicShortText: (string | keyword)[] = [];
  dynamicLongText: (string | keyword)[] = [];

  constructor(private route: ActivatedRoute,
              private dialog: MatDialog, private dynamicTooltipService: DynamicTooltipService) { }

  ngOnInit(): void {
    this.dates = JSON.parse(this.route.snapshot.queryParams.dates);
    this.result = this.route.snapshot.data.compatibility.result;
    this.text = this.route.snapshot.data.compatibility.texte;
    this.shortText = this.route.snapshot.data.compatibility.shortTexte;
    this.dynamicShortText = this.dynamicTooltipService.toDynamicText(this.shortText);
    this.dynamicLongText = this.dynamicTooltipService.toDynamicText(this.text);
    //this.openDialog();
  }

  isString(token: any) {
    return typeof token === 'string';
  }

  getTooltip(token: string | keyword) {
    return (token as keyword).tooltip;
  }

  getWord(token: string | keyword) {
    return (token as keyword).word;
  }

  openDialog(): void {
    const dialog = this.dialog.open(PaymentModalComponent, {
      width: '1050px',
      panelClass: 'modal-panel'
    });
    dialog.afterClosed().subscribe(value => {
      if (value) {
        this.showLong = true;
      }
    });
  }

}

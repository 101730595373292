<div class="custom-container py-5">
  <div class="content py-5">
    <div class="row justify-content-center">
      <div class="col-auto d-flex align-items-center"><p class="white-text large">{{ dates.date1 }}</p></div>
      <div class="col-auto">
        <button *ngIf="dates.coupleType === 1" class="gender-button large selected">
          <svg-icon [svgStyle]="{'width.px': '60'}" src="assets/SVG/avatar-female.svg"></svg-icon>
          <svg-icon [svgStyle]="{'width.px': '60'}" src="assets/SVG/avatar-male.svg"></svg-icon>
        </button>
        <button *ngIf="dates.coupleType === 2" class="gender-button large selected">
          <svg-icon [svgStyle]="{'width.px': '60'}" src="assets/SVG/avatar-female.svg"></svg-icon>
          <svg-icon [svgStyle]="{'width.px': '60'}" src="assets/SVG/avatar-female.svg"></svg-icon>
        </button>
        <button *ngIf="dates.coupleType === 3" class="gender-button large selected">
          <svg-icon [svgStyle]="{'width.px': '60'}" src="assets/SVG/avatar-male.svg"></svg-icon>
          <svg-icon [svgStyle]="{'width.px': '60'}" src="assets/SVG/avatar-male.svg"></svg-icon>
        </button>
      </div>
      <div class="col-auto d-flex align-items-center"><p class="white-text large">{{ dates.date2 }}</p></div>
    </div>
    <div class="p-5">
      <p class="white-paragraph x-large text-center mb-3">
        LA VERITÉ SUR VOTRE RELATION
      </p>
      <p *ngIf="!showLong" class="white-paragraph large">
        <ng-container *ngFor="let token of dynamicShortText">
          <ng-container *ngIf="isString(token)" style="white-space: pre-wrap;"><span [innerHTML]="token"></span></ng-container>
          <ng-container *ngIf="!isString(token)"  style="white-space: pre-wrap;"
            ><b [matTooltip]="getTooltip(token)" style="white-space: pre-wrap;" matTooltipClass="tooltip-text" style="cursor: pointer; color: blueviolet;">
              {{ getWord(token) }}
            </b></ng-container
          >
        </ng-container>
      </p>
      <p  *ngIf="!showLong" class="mt-5 pt-5 text-center white-paragraph medium">Pour dévoiler les 80% restants de la Version allégée et accéder à la Version élaborée<br/>Merci de procéder au paiement</p>
      <div *ngIf="!showLong" class="text-toaster mx-auto mt-3 pointer-on-hover" (click)="openDialog()">
        <p>
          Payer
        </p>
      </div>
      <p *ngIf="showLong" class="white-paragraph large">
        <ng-container *ngFor="let token of dynamicLongText">
          <ng-container *ngIf="isString(token)" style="white-space: pre-wrap;"><span [innerHTML]="token"></span></ng-container>
          <ng-container *ngIf="!isString(token)"  style="white-space: pre-wrap;"
            ><b [matTooltip]="getTooltip(token)" style="white-space: pre-wrap;" matTooltipClass="tooltip-text" style="cursor: pointer; color: blueviolet;">
              {{ getWord(token) }}
            </b></ng-container
          >
        </ng-container>
      </p>
    </div>
  </div>
  <div class="text-right pt-4">
    <button routerLink="/home" class="confirm-btn mr-2">
      <span class="btn-text">Retour au menu</span>
    </button>
  </div>
</div>


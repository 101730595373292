import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  IPayPalConfig,
  ICreateOrderRequest
} from 'ngx-paypal';
import {MainService} from '../../../core/services/main.service';
import {Payment} from '../../../models/payment';
import Swal from 'sweetalert2';
import {environment} from '../../../../environments/environment';
@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss']
})
export class PaymentModalComponent implements OnInit {

  public payPalConfig?: IPayPalConfig;
  public payment: Payment;
  public paymentConfiguration: any;
  public showPayment = false;

  constructor(private dialogRef: MatDialogRef<PaymentModalComponent>, private mainService: MainService) {
    this.initConfig();

  }

  ngOnInit(): void {
  }

  initPayment(): void {
    this.payPalConfig = {
      currency: 'EUR',
      clientId: 'AQKIBdc76_6zloGGrAlTlHAKn63p0p7LsbHSdrFtedlvLhIvAJIIjHw7YIDk-JTWHI_eEFc7An5Mm_I_',
      createOrderOnClient: (data) => this.createOrder(this),
      style: {
        layout: 'vertical',
        size: 'medium',
        shape: 'pill',
        label: 'checkout',
      },
      onApprove: (data, actions) => {
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
        });

      },
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        Swal.fire({
          title: 'Bravo !',
          text: 'Paiement validé avec succès',
          icon: 'success',
          confirmButtonText: 'D\'accord'
        });
        this.validatePayment();
      },
      onCancel: (data, actions) => {
        Swal.fire({
          title: 'Erreur!',
          text: 'Vous avez annulé le paiement',
          icon: 'error',
          confirmButtonText: 'D\'accord'
        });
      },
      onError: err => {
        Swal.fire({
          title: 'Erreur!',
          text: 'Il y\'a un probléme lors du paiement',
          icon: 'error',
          confirmButtonText: 'D\'accord'
        });
      },
      onClick: (data, actions) => {
        this.createPayment();
      },
    };
  }

  private initConfig(): void {
    console.log("Init config");
    this.mainService.getPaymentConfiguration().then(d => {
      this.paymentConfiguration = d;
      this.initPayment();
      this.showPayment = true;
    });
  }

  private createOrder(self): ICreateOrderRequest{
    return {
      intent: 'CAPTURE',
      purchase_units: [{
        amount: {
          currency_code: 'EUR',
          value: self.paymentConfiguration.prediction.toString(),
          breakdown: {
            item_total: {
              currency_code: 'EUR',
              value: self.paymentConfiguration.prediction.toString()
            }
          }
        },
        items: [{
          name: 'Prediction',
          quantity: '1',
          category: 'DIGITAL_GOODS',
          unit_amount: {
            currency_code: 'EUR',
            value: self.paymentConfiguration.prediction.toString(),
          },
        }]
      }]
    };
  }

  createPayment(): void {
    this.mainService.createPayment({
      paymentItems: [
        {
          paymentItemType: 0,
          quantity: 1
        }
      ]
    }).then(d => {
      this.payment = d;
    });
  }

  validatePayment(): void {
    if (this.payment) {
      this.mainService.validatePayment(this.payment.transactionId).then(d => {
        this.payment = d;
        this.dialogRef.close(true);
      });
    } else {
      this.dialogRef.close(true);
    }
  }

}

import { Component, OnInit } from '@angular/core';
import {MainService} from '../../core/services/main.service';
import {OwlOptions} from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-list-ads',
  templateUrl: './list-ads.component.html',
  styleUrls: ['./list-ads.component.scss']
})
export class ListAdsComponent implements OnInit {

  ads = [];

  constructor(private api: MainService) { }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 4
      },
      940: {
        items: 6
      }
    },
    nav: false,
    autoplay: true,
  };

  ngOnInit(): void {
    this.api.getAds().then(d => {
      this.ads = d;
    });
  }

}

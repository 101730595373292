import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Conversation, Message} from '../../../models/conversation';
import {MainService} from '../../../core/services/main.service';
import {ActivatedRoute, Params} from '@angular/router';
import {CredentialsService} from '../../../core/authentication/credentials.service';
import {User} from '../../../models/user';
import {SignalrService} from '../../../core/services/signalr.service';
import * as moment from 'moment';
import {Router} from '@angular/router';

@Component({
  selector: 'app-chat-messages',
  templateUrl: './chat-messages.component.html',
  styleUrls: ['./chat-messages.component.scss']
})
export class ChatMessagesComponent implements OnInit {

  conversation: Conversation;
  id: number;
  input = '';
  user: User;
  showCompatibility = false;
  compatibility: any;

  @ViewChild('listMessages') private listMessagesView: ElementRef;


  constructor(private apiService: MainService,
              private route: ActivatedRoute,
              private credentialsService: CredentialsService,
              private api: MainService,
              private signalrService: SignalrService, private router: Router) {
    this.user = this.credentialsService.credentials?.user;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.id = params.id;
      this.getData();
    });
    this.signalrService.onReceiveMessage().subscribe((m: Message) => {
      if (m.senderId.toString() === this.id.toString() && m.receiverId.toString() === this.user.id.toString()) {
        this.conversation.messages.push(m);
        this.scrollToBottom();
        this.readConversation();
      }
    });
  }

  readConversation(): void {
    this.apiService.readConversation(this.id).then(d => {
      this.apiService.changeMessage();
    });
  }

  getData(): void {
    this.conversation = null;
    this.apiService.getConversationById(this.id).then(d => {
      this.conversation = d;
      this.scrollToBottom();
      this.readConversation();
      const body: any = {};
      body.coupleType = 0;
      if (this.user.gendre === 1 && this.conversation.user.gendre === 1)
      {
        body.coupleType = 1;
      }
      else if (this.user.gendre === 2 && this.conversation.user.gendre === 2)
      {
        body.coupleType = 3;
      }
      else
      {
        body.coupleType = 2;
      }
      body.language = 'FR';
      body.date1 = moment(this.credentialsService.credentials.user.birthDate).format('DD/MM/YYYY');
      body.date2 = moment(this.conversation.user.birthDate).format('DD/MM/YYYY');
      this.api.getCompatibility(body).then(d => {
        this.compatibility = d;
      });
    });
  }

  isMe(message: Message): boolean {
    return message.senderId === this.user.id;
  }

  getUser(message: Message): User {
    const isMe = this.isMe(message);
    return isMe ? this.user : this.conversation.user;
  }

  sendMessage(): void {
    const content = this.input;
    this.input = '';
    this.conversation.messages.push({
      createTime: undefined,
      id: 0,
      isReaded: true,
      type: 0,
      senderId: this.user.id,
      receiverId: this.id,
      message: content,
      content
    });
    this.scrollToBottom();
    this.apiService.addMessage(this.id, content).then(d => {
      this.apiService.changeMessage();
    });
  }

  onFileSelected(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      this.apiService.addPhoto(this.id, file).then(d => {
        this.apiService.changeMessage();
      });

      const reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event2) => { // called once readAsDataURL is completed
        this.conversation.messages.push({
          message: '',
          createTime: undefined,
          id: 0,
          isReaded: true,
          type: 1,
          senderId: this.user.id,
          receiverId: this.id,
          content: event2.target.result
        });
        this.scrollToBottom();
      };
    }
  }
  scrollToBottom(): void {
    const self = this;
    setTimeout(() => {
      try {
        self.listMessagesView.nativeElement.scrollTop = self.listMessagesView.nativeElement.scrollHeight;
      } catch (err) { }
    }, 10);
  }

  async blockUser(): Promise<void> {
    
    const user = this.credentialsService.credentials?.user;
    
    await this.api.blockUser(this.id);
      this.api.getConnectedUser().then(d => {
        const connectedUser: User = d;
        this.credentialsService.setCredentials({
          user: connectedUser,
          token: connectedUser.token
        }, true);
        this.router.navigate(['/premium/profile']);
      }).catch(e => {
        this.credentialsService.setCredentials();
      });
  }
}

import { Injectable } from '@angular/core';
import  wiki  from '../../data/wiki.json';

export type keyword = {
  word: string;
  tooltip: string;
};

@Injectable({
    providedIn: 'root'
  })

export class DynamicTooltipService {
  keywords: { [key: string]: keyword } = wiki;

  //Replace keywords with keyword objects
  toDynamicText(text: string): (string | keyword)[] {
    const res: (string | keyword)[] = [];
    text = text.replace(/\r\n/g, ' <br/><br/> ');
    //console.log(text);
    const tokens = text.split(' ');
    //console.log(tokens);
    let i = 0;
    for (const token of tokens) {
      let keyword = this.keywords[token.toLowerCase()];
      if (keyword) {
        i = res.push(keyword);
      } else {
        if (!res[i]) res[i] = token;
        else res[i] += ' ' + token;
      }
    }
    return res;
  }
}

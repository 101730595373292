<div class="chat-detail minus-plus-panel" *ngIf="conversation">
  <div *ngIf="showCompatibility" class="row">
    <div class="col-12">
      <div class="relation-expanded">
        <div *ngIf="compatibility">
          <p>{{compatibility.texte}}</p>
        </div>
        <div class="profile">
          <img [src]="conversation.user.profileImage || 'assets/images/default-user.jpg'" alt="">
          <p>{{conversation.user.firstName.toUpperCase()}}</p>
        </div>
        <button class="return-btn" (click)="showCompatibility=false">
          <span class="btn-text">Retour</span>
        </button>
      </div>
    </div>
  </div>
  <div class="profile" *ngIf="!showCompatibility">
    <div class="row">
      <div class="col-auto">
        <img [src]="conversation.user.profileImage || 'assets/images/default-user.jpg'" alt="">
      </div>
      <div class="col d-flex align-items-center">
        <div class="name">
          <p>{{conversation.user.firstName}} {{conversation.user.lastName}}</p>
        </div>
      </div>
      <button (click)="showCompatibility = true" class="primary-button top-view pointer-on-hover" style="height: min-content;
      align-self: center;
      margin-right: 15px;">
        <span class="btn-text top-view">Relation</span>
              
      </button>
      <button (click)="blockUser()" class="primary-button top-view pointer-on-hover" style="height: min-content;
      align-self: center;
      margin-right: 15px;">
        <span class="btn-text top-view">Bloquer</span>
              
      </button>
    </div>
  </div>
  <div class="chat-messages mt-3" #listMessages *ngIf="!showCompatibility">
    <div *ngFor="let item of conversation.messages" class="row pb-2 align-items-end" [class.justify-content-end]="isMe(item)">
      <div class="col-auto mb-3" *ngIf="!isMe(item)">
        <img [src]="getUser(item).profileImage || 'assets/images/default-user.jpg'" alt="" class="chat-image">
      </div>
      <div class="col-auto">
        <div class="chat-bubble round" [class.tri-right]="isMe(item)" [class.tri-left]="!isMe(item)" [class.right-in]="isMe(item)" [class.left-in]="!isMe(item)">
          <div class="talk-text">
            <p *ngIf="item.type == 0">{{item.content}}</p>
            <img style="height: 252px;" [src]="item.content" *ngIf="item.type == 1"/>
          </div>
        </div>
      </div>
      <div class="col-auto" *ngIf="isMe(item)">
        <img [src]="getUser(item).profileImage || 'assets/images/default-user.jpg'" alt="" class="chat-image">
      </div>
    </div>
  </div>
  <div class="chat-input" *ngIf="!showCompatibility">
    <img src="assets/SVG/plus.png" alt="" style="width: auto;" (click)="image.click()">
    <input [(ngModel)]="input" type="text" (keyup.enter)="sendMessage()">
    <input class="d-none" id="file-input" #image type="file" (change)="onFileSelected($event)"  />
    <img src="assets/SVG/right-arrow.png" (click)="sendMessage()"/>
  </div>
</div>

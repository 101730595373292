<section class="container-fluid dark-section py-5">
  <div class="row">
    <div class="col-1">
      <div class="ads-box">
        <p>ADS</p>
      </div>
    </div>
    <div class="col-10">
      <owl-carousel-o [options]="customOptions" #carousel>
        <ng-template *ngFor="let item of ads" carouselSlide>
            <img [src]="item.image" />
        </ng-template>
      </owl-carousel-o>
    </div>
    <div class="col-1"></div>
  </div>
</section>

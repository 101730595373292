import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MainService} from '../../../core/services/main.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import {CredentialsService} from '../../../core/authentication/credentials.service';
import {User} from '../../../models/user';
import {Router} from '@angular/router';
import {OwlOptions} from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  introVideos = ['intro1.mp4', 'intro2.mp4', 'intro3.mp4'];
  introVideoIndex = 1;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    autoplay: true,
    responsive: {
      0: {
        items: 1
      },
    },
    nav: false
  };

  images = [
    "../../../../assets/images/bg/bg1.jpg",
    "../../../../assets/images/bg/bg2.jpg",
    "../../../../assets/images/bg/bg3.jpg",
    "../../../../assets/images/bg/bg4.jpg",
    "../../../../assets/images/bg/bg5.jpg",
  ];

  constructor(private api: MainService,
              private credentialsService: CredentialsService,
              private router: Router, ) {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
  }

  introVideoEnded(): void {
    this.introVideoIndex = (this.introVideoIndex + 1) % this.introVideos.length;
  }

  sumbit(): void {
    const body = this.form.value;
    if (this.form.valid) {
      this.api.login(body).then(d => {
        const user: User = d;
        this.credentialsService.setCredentials({
          user,
          token: user.token
        }, true);
        this.api.getConnectedUser().then(d => {
          const user: User = d;
          this.credentialsService.setCredentials({
            user,
            token: user.token
          }, true);
          this.router.navigate(['/premium/profile']);
        }).catch(e => {
          this.credentialsService.setCredentials();
        });
      }).catch(e => {
        Swal.fire({
          title: 'Erreur!',
          text: 'Mail ou mot de passe incorrect',
          icon: 'error',
          confirmButtonText: 'D\'accord'
        });
      });
    } else {
      Swal.fire({
        title: 'Erreur!',
        text: 'Vous devez remplir le formulaire',
        icon: 'error',
        confirmButtonText: 'D\'accord'
      });
    }
  }
}

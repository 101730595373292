<section class="text-center py-4">
  <h2 class="primary-title">Les membres qui ont trouvé leur âme soeur</h2>
</section>
<section class="container-fluid dark-section py-3 mb-5">
  <div class="row justify-content-between">
    <div class="col-1 d-flex flex-column justify-content-center">
      <div class="prove-it-box">
        <p>I<br/>FOUND<br/>YOU</p>
      </div>
    </div>
    <div class="col-10">
      <owl-carousel-o [options]="customOptions" #carousel>
        <ng-template *ngFor="let item of couples" carouselSlide>
          <div class="couple-circle">
            <img class="image-circle" [src]="item.image" />
          </div>
          <p class="text-center m-0 text-uppercase">
            {{item.description}}
          </p>
          <p class="text-center m-0 text-uppercase">
            {{item.name}}
          </p>
        </ng-template>
      </owl-carousel-o>
    </div>
    <div class="col-1"></div>
  </div>
</section>

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {Observable, Subject} from 'rxjs';
import {Message} from '../../models/conversation';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  private changeMessages = new Subject<any>();

  constructor(private http: HttpClient) { }

  changeMessage(): void {
    this.changeMessages.next(Math.random());
  }

  public onChangeMessages(): Observable<Message> {
    return this.changeMessages.asObservable();
  }

  getCompatibility(body: any): Promise<any> {
    return this.http.post(`${environment.BASE_URL}/api/compatibility`, body).toPromise();
  }

  getVideos(): Promise<any> {
    return this.http.get(`${environment.BASE_URL}/api/videos`).toPromise();
  }

  getCountries(): Promise<any> {
    return this.http.get(`${environment.BASE_URL}/api/countries`).toPromise();
  }

  getDiscoveredAmes(): Promise<any> {
    return this.http.get(`${environment.BASE_URL}/api/foundedcouples`).toPromise();
  }

  getAds(): Promise<any> {
    return this.http.get(`${environment.BASE_URL}/api/ads`).toPromise();
  }

  getConnectedUser(): Promise<any> {
    return this.http.get(`${environment.BASE_URL}/api/user/me`).toPromise();
  }

  createUser(body: any): Promise<any> {
    return this.http.post(`${environment.BASE_URL}/api/user/signup`, body).toPromise();
  }

  editUser(id: number, body: any): Promise<any> {
    return this.http.put(`${environment.BASE_URL}/api/user/${id}`, body).toPromise();
  }

  login(body: any): Promise<any> {
    return this.http.post(`${environment.BASE_URL}/api/user/login`, body).toPromise();
  }

  socialLogin(body: any): Promise<any> {
    return this.http.post(`${environment.BASE_URL}/api/user/login/social`, body).toPromise();
  }

  uploadUserImage(file: File): Promise<any> {
    const formData = new FormData();
    formData.append('image', file);

    return this.http.post(`${environment.BASE_URL}/api/user/profile/image/upload`, formData).toPromise();
  }

  getPaymentConfiguration(): Promise<any> {
    return this.http.get(`${environment.BASE_URL}/api/payment/config`).toPromise();
  }

  createPayment(body: any): Promise<any> {
    return this.http.post(`${environment.BASE_URL}/api/payment`, body).toPromise();
  }

  validatePayment(transaction: string): Promise<any> {
    return this.http.post(`${environment.BASE_URL}/api/payment/validate/` + transaction, null).toPromise();
  }

  searchUsers(): Promise<any> {
    return this.http.get(`${environment.BASE_URL}/api/compatibility/search`).toPromise();
  }

  addUser(id): Promise<any> {
    return this.http.post(`${environment.BASE_URL}/api/user/soul-mate/` + id, {}).toPromise();
  }

  blockUser(id): Promise<any> {
    return this.http.post(`${environment.BASE_URL}/api/user/soul-mate-block/` + id, {}).toPromise();
  }

  getConversations(): Promise<any> {
    return this.http.get(`${environment.BASE_URL}/api/conversations/message`, {}).toPromise();
  }

  getConversationById(id): Promise<any> {
    return this.http.get(`${environment.BASE_URL}/api/conversations/message/` + id, {}).toPromise();
  }

  readConversation(id): Promise<any> {
    return this.http.put(`${environment.BASE_URL}/api/conversations/message/` + id + '/read', {}).toPromise();
  }

  addMessage(id, message): Promise<any> {
    return this.http.post(`${environment.BASE_URL}/api/conversations/message/${id}/message`, {
      message
    }).toPromise();
  }

  addPhoto(id, file: File): Promise<any> {
    const formData = new FormData();
    formData.append('image', file);

    return this.http.post(`${environment.BASE_URL}/api/conversations/message/${id}/message/image`, formData).toPromise();
  }

}

<section class="container-fluid dark-section">
  <div class="row">
    <div class="col-1 pointer-on-hover" routerLink="/home">
      <img style="height: 100px;" src="../../../../assets/SVG/born2meet-blue-white.png" alt="">
    </div>
    <div class="col-1 text-right pt-4">
      <button routerLink="/home" class="return-btn mr-2">
        <span class="btn-text">Accueil</span>
      </button>
    </div>
    <div class="col text-center d-flex justify-content-center align-items-center">
      <h1 class="subscribe-title">WHERE THE FIRST FLAME STARTS</h1>
    </div>
    <div class="col-auto pt-2" *ngIf="!user">
      <h4 class="proof-title">PREMIUM</h4>
      <button class="primary-button" routerLink="/premium/subscribe">
        <span class="btn-text">S'inscrire</span>
      </button>
      <a class="header-link" routerLink="/premium/login">DÉJÀ MEMBRE?</a>
    </div>
    <div class="col-auto pt-2 user-item pointer-on-hover" *ngIf="user" routerLink="/premium/profile">
      <img *ngIf="user.profileImage" [src]="user.profileImage"/>
      <img *ngIf="!user.profileImage" [src]="'assets/SVG/upload-avatar.png'"/>
      <div class="">
        <h5 class="d-block">{{user.firstName}} {{user.lastName}}</h5>        
        <span *ngIf="user.state == 0">Pas d'abonnement actif</span>
        <span *ngIf="user.state == 1">Abonnement expiré le {{user.packEndDate | date}}</span>
        <span *ngIf="user.state == 2">Abonnement valide jusqu'au {{user.packEndDate | date}}</span>
      </div>
    </div>
  </div>
</section>
<router-outlet></router-outlet>
<app-list-ads></app-list-ads>
<app-list-discovered-ames></app-list-discovered-ames>

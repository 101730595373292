import { Component, OnInit } from '@angular/core';
import {CredentialsService} from '../../core/authentication/credentials.service';
import {User} from '../../models/user';

@Component({
  selector: 'app-premium-content',
  templateUrl: './premium-content.component.html',
  styleUrls: ['./premium-content.component.scss']
})
export class PremiumContentComponent implements OnInit {

  public user: User;
  constructor(private credentialsService: CredentialsService) { }

  ngOnInit(): void {
    if (this.credentialsService.isAuthenticated()) {
      this.user = this.credentialsService.credentials?.user;
    }
    this.credentialsService.userChangedObservable.subscribe(d => {
      this.user = this.credentialsService.credentials?.user;
    });
  }

}

import { Component, OnInit } from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {MainService} from '../../core/services/main.service';

@Component({
  selector: 'app-list-discovered-ames',
  templateUrl: './list-discovered-ames.component.html',
  styleUrls: ['./list-discovered-ames.component.scss']
})
export class ListDiscoveredAmesComponent implements OnInit {

  couples = [];

  constructor(private api: MainService) { }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: false,
    autoplay: true,
  };

  ngOnInit(): void {
    this.api.getDiscoveredAmes().then(d => {
      this.couples = d;
    });
  }

}

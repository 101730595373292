<section class="container-fluid login-section " *ngIf="isEditingProfile != null" [class.signup-page]="!isEditingProfile" [class.edit-profile]="isEditingProfile">
  <div class="carousel-items">
    <owl-carousel-o [options]="customOptions">
      <ng-template *ngFor="let item of images" carouselSlide>
        <img [src]="item"/>
      </ng-template>
    </owl-carousel-o>
  </div>
  <div class="row ">
    <div class="col position-relative d-flex flex-column justify-content-center">
      <!-- <img *ngIf="!isEditingProfile" class="subscribe-banner" src="../../../../assets/SVG/subscribe-banner.png" alt=""> -->
      <div class="subscribe-text">
        <p>
          VIVEZ UNE RELATION COMPATIBLE AVEC <br/>BORN TO MEET
        </p>
      </div>
    </div>
    <div class="col-5" >
      <div class="login-container">
          <h2 class="login-title" *ngIf="isEditingProfile">Modifier mon profil</h2>
          <h2 class="login-title" *ngIf="!isEditingProfile">Inscrivez-Vous</h2>
          <form class="login-form" [formGroup]="form">
            <div class="row pt-3">
              <div class="col">
                <label>JE SUIS UN(E):</label>
                <div class="pb-3">
                  <button class="form-control gender-choice" (click)="setGender(2)" [class.active]="isGenderSelected(2)">
                    <svg-icon [svgStyle]="{'width.px': '24'}" src="assets/SVG/avatar-female.svg"></svg-icon>
                  </button>
                  <button class="form-control gender-choice" (click)="setGender(1)" [class.active]="isGenderSelected(1)">
                    <svg-icon [svgStyle]="{'width.px': '24'}" src="assets/SVG/avatar-male.svg"></svg-icon>
                  </button>
                </div>
                <div class="py-1">
                  <input formControlName="firstName" type="text" class="form-control" placeholder="NOM OU PSEUDONYME">
                </div><!-- 
                <div class="py-1">
                  <input formControlName="lastName" type="text" class="form-control" placeholder="NOM">
                </div> -->
                <div class="py-1">
                  <input formControlName="birthDate" type="date" class="form-control text-uppercase" placeholder="JJ/MM/AAAA" (change)="dateChanged()">
                </div>
                <div class="py-1">
                  <mat-select formControlName="countryId" placeholder="PAYS" class="form-control">
                    <mat-option *ngFor="let item of countries" [value]="item.id">{{item.name}}                             </mat-option>
                  </mat-select>
                </div>
                <div class="py-1" *ngIf="!isEditingProfile">
                  <input formControlName="email" type="email" class="form-control" placeholder="MAIL">
                </div>
                <div class="py-1 inner-addon right-addon" *ngIf="!isEditingProfile">
                  <input formControlName="password" [type]="showPassword ? 'text' : 'password'" class="form-control" placeholder="MOT DE PASSE">
                  <div>
                    <i alt="show" class="far fa-eye eye-show"
                       (click)="showPassword = !showPassword"
                       [class.hide]="showPassword"></i>
                    <i alt="hide" class="far fa-eye-slash eye-hide"
                       (click)="showPassword = !showPassword"
                       [class.hide]="!showPassword"></i>
                  </div>
                </div>
                <div class="py-1  inner-addon right-addon" *ngIf="!isEditingProfile">
                  <input formControlName="password2" [type]="showPassword2 ? 'text' : 'password'" class="form-control" placeholder="CONFIRMEZ VOTRE MOT DE PASSE">
                  <div>
                    <i alt="show" class="far fa-eye eye-show"
                       (click)="showPassword2 = !showPassword2"
                       [class.hide]="showPassword2"></i>
                    <i alt="hide" class="far fa-eye-slash eye-hide"
                       (click)="showPassword2 = !showPassword2"
                       [class.hide]="!showPassword2"></i>
                  </div>
                </div>
                <div class="py-1" *ngIf="!isEditingProfile">
                  <button class="facebook-button" (click)="signInWithFB()">
                    SE CONNECTER AVEC FACEBOOK
                  </button>
                </div>
                <div class="py-1" *ngIf="!isEditingProfile">
                  <button class="google-button" (click)="signInWithGoogle()">
                    SE CONNECTER AVEC GOOGLE
                  </button>
                </div>
                <div class="py-1">
                  <mat-select formControlName="religion" placeholder="RELIGIONS" class="form-control">
                    <mat-option *ngFor="let item of religions" [value]="item">{{item}}</mat-option>
                  </mat-select>
                </div>
                <div class="py-1">
                  <mat-select formControlName="language" placeholder="LANGUE" class="form-control">
                    <mat-option *ngFor="let item of languages" [value]="item">{{item}}</mat-option>
                  </mat-select>
                </div>
              </div>
              <div class="col-5 text-center" >
                <img *ngIf="userImage == ''" class="user-imag pointer-on-hover" src="assets/SVG/upload-avatar.png" alt="" (click)="image.click()">
                <img *ngIf="userImage != ''" class="user-image pointer-on-hover" [src]="userImage" alt="" (click)="image.click()">
                <h4 class="change-image mt-3 pointer-on-hover" (click)="image.click()"><span *ngIf="userImage == ''">Insérer</span><span *ngIf="userImage != ''">Changer</span> votre<br/>photo</h4>
              </div>
              <input class="d-none" id="file-input" #image type="file" (change)="onFileSelected($event)" />
            </div>
            <h2 class="login-title text-left mt-5">DISCOVER FOR ME?</h2>
            <label class="color-dark">JE CHERCHE</label>
            <div formGroupName="userPartnerPreference">
              <div class="pb-3">
                <button class="form-control gender-choice" (click)="setPartnerGender(2)" [class.active]="isPartnerGenderSelected(2)">
                  <svg-icon [svgStyle]="{'width.px': '24'}" src="assets/SVG/avatar-female.svg"></svg-icon>
                </button>
                <button class="form-control gender-choice" (click)="setPartnerGender(1)" [class.active]="isPartnerGenderSelected(1)">
                  <svg-icon [svgStyle]="{'width.px': '24'}" src="assets/SVG/avatar-male.svg"></svg-icon>
                </button>
              </div>
              <div class="row">
                <div class="col-auto">
                  <label class="color-dark">TRANCHE D’ÂGE :</label>
                </div>
                <div class="col d-flex">
                  <div class="left-arrow">

                  </div>
                  <div class="flex-grow-1">
                    <div class="custom-slider">
                      <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"></ngx-slider>
                    </div>
                  </div>
                  <div class="right-arrow"></div>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <label class="color-dark">PAYS:</label>
                </div>
                <div class="col-4">
                  <label class="color-dark">RELIGIONS:</label>
                </div>
                <div class="col-4">
                  <label class="color-dark">LANGUE DE CHAT:</label>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <mat-select formControlName="countries" placeholder="PAYS" class="form-control" multiple>
                    <mat-option [disabled]="form.value.userPartnerPreference.countries.length >= 5 && !form.value.userPartnerPreference.countries.includes(item.id)" *ngFor="let item of countries" [value]="item.id">{{item.name}}</mat-option>
                  </mat-select>
                </div>
                <div class="col-4">
                  <mat-select formControlName="religion" placeholder="RELIGIONS" class="form-control">
                    <mat-option *ngFor="let item of religions" [value]="item">{{item}}</mat-option>
                  </mat-select>
                </div>
                <div class="col-4">
                  <mat-select formControlName="language" placeholder="LANGUE" class="form-control">
                    <mat-option *ngFor="let item of languages" [value]="item">{{item}}</mat-option>
                  </mat-select>
                </div>
              </div>
            </div>
            <div class="d-flex pt-3 justify-content-end">
              <button class="primary-button" (click)="sumbit()">
                <span class="btn-text">Valider</span>
              </button>
            </div>
          </form>
        </div>
    </div>
  </div>
</section>

import {Component, EventEmitter, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MainService} from '../../../core/services/main.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthService} from 'angularx-social-login';
import {User} from '../../../models/user';
import {CredentialsService} from '../../../core/authentication/credentials.service';
import {NavigationEnd, Router} from '@angular/router';
import {Options} from '@angular-slider/ngx-slider';
import {OwlOptions} from 'ngx-owl-carousel-o';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {

  form: FormGroup;
  file: File;
  religions = ['Aucune religion', 'Bouddhisme', 'Christianisme', 'Hindouisme', 'Islam', 'Judaisme', 'Aucune importance'];
  languages = ['Anglais', 'Arabe', 'Français', 'Russe'];
  countries = [];
  urlPreview: string | ArrayBuffer;
  introVideos = ['intro1.mp4', 'intro2.mp4', 'intro3.mp4'];
  introVideoIndex = 1;
  isEditingProfile;
  public showPassword: boolean;
  public showPassword2: boolean;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    autoplay: true,
    responsive: {
      0: {
        items: 1
      },
    },
    nav: false
  };

  images = [
    '../../../../assets/images/bg/bg1.jpg',
    '../../../../assets/images/bg/bg2.jpg',
    '../../../../assets/images/bg/bg4.jpg'
  ];

  minValue = 60;
  maxValue = 70;
  options: Options = {
    floor: 18,
    ceil: 100,
    step: 1,
    minRange: 2,
    maxRange: 20,
    pushRange: true,
  };

  constructor(private api: MainService,
              private authService: SocialAuthService,
              private router: Router,
              private credentialsService1: CredentialsService,
              private credentialsService: CredentialsService, ) {
    this.form = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.nullValidator]),
      birthDate: new FormControl('', [Validators.required]),
      gendre: new FormControl('', [Validators.required]),
      countryId: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      password2: new FormControl('', [Validators.required]),
      religion: new FormControl('', [Validators.required]),
      language: new FormControl('', [Validators.required]),
      profileImage: new FormControl('', []),
      socialToken: new FormControl('', []),
      userPartnerPreference: new FormGroup({
        gendre: new FormControl('', [Validators.required]),
        age: new FormControl('35', [Validators.required]),
        countries: new FormControl([], []),
        religion: new FormControl('', [Validators.required]),
        language: new FormControl('', [Validators.required]),
      })
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isEditingProfile = event.url === '/premium/profile/edit';
        if (this.isEditingProfile && this.credentialsService.credentials.user) {
          this.form.removeControl('password');
          this.form.removeControl('password2');
          const body: any = this.credentialsService.credentials.user;
          body.userPartnerPreference.countries = this.credentialsService.credentials.user.userPartnerPreference.countries.map(d => d.countryId);
          this.form.patchValue(body);
          const date = moment(this.credentialsService.credentials.user.birthDate).format('YYYY-MM-DD');
          this.form.controls.birthDate.patchValue(date);
          if (!body.userPartnerPreference.ageMin || !body.userPartnerPreference.ageMax) {
            this.dateChanged();
          } else {
            this.dateChanged(false);
            this.minValue = body.userPartnerPreference.ageMin;
            this.maxValue = body.userPartnerPreference.ageMax;
          }
        }
      }
    });
  }

  introVideoEnded(): void {
    this.introVideoIndex = (this.introVideoIndex + 1) % this.introVideos.length;
    console.log(this.introVideoIndex);
  }

  onFileSelected(event): void {
    if (event.target.files && event.target.files[0]) {
      this.file = event.target.files[0];

      const reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event2) => { // called once readAsDataURL is completed
        this.urlPreview = event2.target.result;
      };
    }
  }

  ngOnInit(): void {
    this.api.getCountries().then((d) => {
      this.countries = d;
    });
    this.authService.authState.subscribe((user) => {
      this.form.patchValue({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        socialToken: user.id,
        profileImage: user.photoUrl,
      });
      this.authService.signOut(true);
      this.api.socialLogin({
        socialToken: user.id
      }).then(user => {
        this.saveUser(user);
      });
    });
  }

  setGender(value: number): void {
    this.form.patchValue({
      gendre: value
    });
  }

  isGenderSelected(value: number): boolean {
    return this.form.value.gendre === value;
  }

  setPartnerGender(value: number): void {
    this.form.patchValue({
      userPartnerPreference: {
        gendre: value
      }
    });
  }

  isPartnerGenderSelected(value: number): boolean {
    return this.form.value.userPartnerPreference.gendre === value;
  }

  sumbit(): void {
    const body = this.form.value;
    if (this.form.valid) {
      body.birthDate = moment(body.birthDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
      body.userPartnerPreference.ageMin = this.minValue;
      body.userPartnerPreference.ageMax = this.maxValue;
      if (body.password !== body.password2 && !this.isEditingProfile) {
        Swal.fire({
          title: 'Erreur!',
          text: 'Les deux mots de passe ne sont pas identiques',
          icon: 'error',
          confirmButtonText: 'D\'accord'
        });
        return;
      }
      let req;
      if (this.isEditingProfile) {
        req = this.api.editUser(this.credentialsService.credentials.user.id, body);
      } else {
        req = this.api.createUser(body);
      }
      req.then(d => {
        if (this.file) {
          this.saveUser(d, false);
          this.api.uploadUserImage(this.file).then(d2 => {
            this.saveUser(d2);
          });
        } else {
          this.saveUser(d);
        }
      }).catch(e => {
        Swal.fire({
          title: 'Erreur!',
          text: e.error.message,
          icon: 'error',
          confirmButtonText: 'D\'accord'
        });
      });
    } else {
      Swal.fire({
        title: 'Erreur!',
        text: 'Vous devez remplir le formulaire',
        icon: 'error',
        confirmButtonText: 'D\'accord'
      });
    }
  }

  saveUser(user: User, withRedirect = true): void {
    this.credentialsService.setCredentials({
      user,
      token: user.token
    }, true);
    this.api.getConnectedUser().then(d => {
      const user: User = d;
      this.credentialsService.setCredentials({
        user,
        token: user.token
      }, true);
      if (withRedirect) {
        this.router.navigate(['/premium/profile']);
      }
    }).catch(e => {
      this.credentialsService.setCredentials();
    });
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  get userImage(): string | ArrayBuffer {
    return this.file ? this.urlPreview : this.form.value.profileImage;
  }

  dateChanged(changeValues = true): void {
    console.log('Date changed', changeValues);
    const dateString = this.form.value.birthDate;
    const momentDate = moment(dateString, 'YYYY-MM-DD');
    const now = moment(new Date());
    const diff = Math.max(Math.round(now.diff(momentDate, 'years', true)), 0);
    const newOptions: Options = Object.assign({}, this.options);
    newOptions.floor = Math.max(0, diff - 10);
    newOptions.ceil = Math.max(10, diff + 10);
    if (changeValues) {
      this.minValue = newOptions.floor;
      this.maxValue = newOptions.ceil;
    }
    this.options = newOptions;
  }
}

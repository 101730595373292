<mat-dialog-content>
  
  <div >
    <!-- <div class="text-toaster">
      <p>
        You need to pay before you see compatibility
      </p>
    </div> -->
    <!-- <img class="top-logo" alt="born2meet" src="assets/SVG/born2meet.svg"> -->
    <div class="d-none row justify-content-between">
      <div class="col-6 p-0">
        <div class="wide-container">
          <p>Paiment sécurisé par carte bancaire</p>
        </div>
      </div>
      <div class="col-5 p-0">
        <div class="narrow-container">
          <p>Paiment sécurisé par SMS</p>
        </div>
      </div>
    </div>
    <div class="d-none row justify-content-between">
      <div class="col-6">
        <div class="form-row">
          <div class="col-5 text-right">
            <label class="form-label">*Titulaire de la carte :</label>
          </div>
          <div class="col-7">
            <input type="text" class="form-input">
          </div>
        </div>
        <div class="form-row">
          <div class="col-5 text-right">
            <label class="form-label">*Numéro de la carte :</label>
          </div>
          <div class="col-7">
            <input type="number" class="form-input">
          </div>
        </div>
        <div class="form-row">
          <div class="col-5 text-right">
            <label class="form-label">*Date d'expiration :</label>
          </div>
          <div class="col">
            <mat-select class="form-input">
              <mat-option value="01">01</mat-option>
              <mat-option value="02">02</mat-option>
              <mat-option value="03">03</mat-option>
              <mat-option value="04">04</mat-option>
              <mat-option value="05">05</mat-option>
              <mat-option value="06">06</mat-option>
              <mat-option value="07">07</mat-option>
              <mat-option value="08">08</mat-option>
              <mat-option value="09">09</mat-option>
              <mat-option value="10">10</mat-option>
              <mat-option value="11">11</mat-option>
              <mat-option value="12">12</mat-option>
            </mat-select>
          </div>
          <div class="col">
            <input type="number" class="form-input">
          </div>
        </div>
        <div class="form-row">
          <div class="col-5 text-right">
            <label class="form-label">*CVV :</label>
          </div>
          <div class="col">
            <input type="number" class="form-input">
          </div>
          <div class="col"></div>
        </div>
      </div>
      <div class="col-5">
        <div class="form-row">
          <div class="col-5 text-right">
            <label class="form-label">*N° téléphone :</label>
          </div>
          <div class="col-7">
            <input type="number" class="form-input">
          </div>
        </div>
        <div class="form-row pb-1 align-items-center">
          <div class="col-5 text-right">
            <button class="gold-btn">
              Valider
            </button>
          </div>
          <div class="col-7">
            <p class="small-text">
              Vous allez recevoir un code de validation
            </p>
          </div>
        </div>
        <div class="form-row">
          <div class="col-5 text-right">
            <label class="form-label">*CODE :</label>
          </div>
          <div class="col-7">
            <input type="password" class="form-input">
          </div>
        </div>
      </div>
    </div>
    <div class=" row justify-content-between">
      <div class="col-12 p-0">
        <div class="wide-container text-center">
          <div>
            <p>Paiement</p>
            <button class="premium-btn mt-3 mb-5">
              <span class="btn-text">{{paymentConfiguration.prediction}} euro</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <p class="payment-text text-center">
      Pour dévoiler les 80% restants de la Version allégée et accéder à la Version élaborée,Merci de procéder au paiement
    </p>

    <div class="row" *ngIf="showPayment">
      <div class="col-6 mx-auto mt-5">
        <ngx-paypal [config]="payPalConfig"></ngx-paypal>

      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end pt-4">
    <button mat-dialog-close class="close-btn">
      Annuler
    </button>
  </div>
</mat-dialog-content>

import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import * as moment from 'moment';
import {CompatibilityResults} from '../../models/compatibility-results';
import {MainService} from '../../core/services/main.service';
import Swal from 'sweetalert2';
import {User} from '../../models/user';
import {CredentialsService} from '../../core/authentication/credentials.service';

@Component({
  selector: 'app-premium',
  templateUrl: './premium.component.html',
  styleUrls: ['./premium.component.scss']
})
export class PremiumComponent implements OnInit {

  form: FormGroup = this.fb.group({
    date1: ['', [Validators.required]],
    date2: ['', [Validators.required]],
    coupleType: [1],
    language: ['FR', [Validators.required]],
  });
  private fragment: string;
  public user: User;

  @ViewChild('loopVideo') loopVideo: ElementRef;
  @ViewChild('redLoopVideo') redLoopVideo: ElementRef;
  @ViewChild('blueLoopVideo') blueLoopVideo: ElementRef;
  @ViewChild('yellowLoopVideo') yellowLoopVideo: ElementRef;

  showRed = false;
  showBlue = false;
  showYellow = false;

  CompatibilityResults = CompatibilityResults;

  result = null;
  loading = false;

  body: any;

  introVideos = ['intro1.mp4', 'intro2.mp4', 'intro3.mp4'];
  introVideoIndex = 1;

  constructor(private fb: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private credentialsService: CredentialsService,
              private api: MainService, ) {
    router.events.subscribe(s => {
      if (s instanceof NavigationEnd) {
        const tree = router.parseUrl(router.url);
        if (tree.fragment) {
          const element = document.querySelector('#' + tree.fragment);
          if (element) { element.scrollIntoView(true); }
        }
      }
    });
  }

  ngOnInit(): void {
    // setTimeout(() => {
    //   this.loading = true;
    //   this.showCompatibleResult();
    //   setTimeout(() => {
    //     this.showMoyenCompatibleResult();
    //     setTimeout(() => {
    //       this.showNonCompatibleResult();
    //     }, 10000);
    //   }, 10000);
    // }, 3000);
    if (this.credentialsService.isAuthenticated()) {
      this.user = this.credentialsService.credentials?.user;
    }
    this.credentialsService.userChangedObservable.subscribe(d => {
      this.user = this.credentialsService.credentials?.user;
    });
  }

  logout(): void {
    this.credentialsService.setCredentials();
  }

  getCoupleType(): number {
    return this.form.get('coupleType').value;
  }

  setCoupleType(type: number): void {
    this.form.get('coupleType').patchValue(type);
  }

  submitDates(): void {
    if (this.form.valid) {
      this.body = this.form.value;
      this.body.date1 = moment(this.body.date1, 'YYYY-MM-DD').format('DD/MM/YYYY');
      this.body.date2 = moment(this.body.date2, 'YYYY-MM-DD').format('DD/MM/YYYY');
      this.loading = true;
      this.api.getCompatibility(this.body).then(d => {
        this.result = d.result;
        this.changeVideoLoops();
      });
    } else {
      Swal.fire({
        title: 'Erreur!',
        text: 'Vous devez remplir le formulaire',
        icon: 'error',
        confirmButtonText: 'D\'accord'
      });
    }
  }

  videoEnded(): void {
    this.router.navigate(
      ['/premium/truth'],
      {
        queryParams: {
          dates: JSON.stringify(this.body)
        }
      }
    );
  }

  introVideoEnded(): void {
    this.introVideoIndex = (this.introVideoIndex + 1) % this.introVideos.length;
  }

  changeVideoLoops(): void {
    switch (this.result) {
      case CompatibilityResults.Compatible: {
        this.redLoopVideo.nativeElement.play();
        break;
      }
      case CompatibilityResults.NonCompatible: {
        this.blueLoopVideo.nativeElement.play();
        break;
      }
      case CompatibilityResults.MoyenCompatible: {
        this.yellowLoopVideo.nativeElement.play();
        break;
      }
    }
  }
}

<footer>
  <div class="container">
    <div class="row">
      <div class="col-3 d-flex align-items-center">
        <div class="w-100">
          <h5 class="footer-title">Suivez nous sur</h5>
          <div>
            <ul class="social-media">
              <li>
                <a href="#">
              <span class="fa-stack fa-1x">
                <i class="fa fa-circle fa-stack-2x facebook-blue"></i>
                <i class="fa fa-facebook fa-stack-1x fa-inverse"></i>
              </span>
                </a>
              </li>
              <li>
                <a href="#">
              <span class="fa-stack fa-1x">
                <i class="fa fa-circle fa-stack-2x instagram-gradient"></i>
                <i class="fa fa-instagram fa-stack-1x fa-inverse"></i>
              </span>
                </a>
              </li>
              <li>
                <a href="#">
              <span class="fa-stack fa-1x">
                <i class="fa fa-circle fa-stack-2x twitter-blue"></i>
                <i class="fa fa-twitter fa-stack-1x fa-inverse"></i>
              </span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span class="fa-stack fa-1x">
                    <i class="fa fa-circle fa-stack-2x youtube-red"></i>
                    <i class="fa fa-youtube-play fa-stack-1x fa-inverse"></i>
                  </span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span class="fa-stack fa-1x">
                    <i class="fa fa-circle fa-stack-2x linkedin-blue"></i>
                    <i class="fa fa-linkedin fa-stack-1x fa-inverse"></i>
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div>
            <p class="footer-text"></p>
            <p class="footer-text">
              © 2021 BORN TO MEET
            </p>
          </div>
        </div>
      </div>
      <div class="col-6 d-flex align-items-center">
        <p class="footer-large-text px-lg-4">
          FAITES CONFIANCE À BORN TO MEET POUR RÉUSSIR VOTRE VIE DE COUPLE SUR DES BASES SCIENTIFIQUES
        </p>
      </div>
      <div class="col-3">
        <div class="d-flex align-items-center">
          <div>
            <img class="phone-img" src="assets/SVG/Asset%202.png" alt="">
          </div>
          <div>
            <a class="google-play-badge" href='http://play.google.com/store/?pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
              <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
            </a>
            <a class="app-store-badge" href='http://play.google.com/store/?pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
              <img alt='Get it on Google Play' src='assets/SVG/appstore-badge.png'/>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

<section class="container-fluid dark-section py-5 mb-5">
  <div class="row">
    <div class="col-1">
      <div class="ads-box">
        <p>ÂMES</p>
      </div>
    </div>
    <div class="col-10 carousel-container">
      <owl-carousel-o [options]="customOptions" #carousel>
        <ng-template *ngFor="let item of user.soulMates" carouselSlide>
          <div class="text-center">
            <h4 class="proof-title text-white">
              {{item.firstName.toUpperCase()}}
            </h4>
          </div>
          <div class="d-flex justify-content-center">
            <div class="carousel-element" >
              <img [src]="item.profileImage || 'assets/images/default-user.jpg'" alt="">
              <div style="margin-top: 25px;">
                <div  class="element-row">
                  <div class="image">
                    <div class="d-flex justify-content-center">
                      <img class="detail-image" style="margin-bottom: 14px;" [src]="item.profileImage || 'assets/images/default-user.jpg'" alt="">
                    </div>
                  </div>
                  <div class="text text-center">
                    <p class="identity">
                      {{item.firstName.toUpperCase()}}
                    </p>
                    <p class="identity">
                      {{item.country.name.toUpperCase()}}
                    </p>
                  </div>
                </div>
                <button [routerLink]="['/premium/chat-list', item.id]" class="premium-btn d-block mb-3 mx-auto">Envoyer un message</button>
              </div>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
    <div class="col-1"></div>
  </div>
</section>

<div class="loading-header video-background">
  <video autoplay="" loop="" muted="" playsinline="" class="page-left__media" data-id="2">
    <source src="assets/video/BTM_V5.mp4" type="video/mp4">
  </video>
  <div class="loading-text d-flex flex-column align-items-center justify-content-center">
    <p class="saving text-center">En Cours
      <span>•</span>
      <span>•</span>
      <span>•</span>
      <span>•</span>
      <span>•</span>
      <span>•</span>
      <span>•</span></p>
    <img class="born2meet-logo" src="assets/SVG/born2meet.png" alt="">
  </div>
  <div class="text-center">
    <p class="bottom-text">DE RECHERCHE DE VOTRE ÂME SOEUR</p>
  </div>
</div>
<div class="container py-3">
  <div class="row">
    <div class="col-auto">
      <p class="advert-text">
        <span><strong>BORN TO MEET</strong> looks for your soulmate</span><br>
        <span class="spacing">among 11700 compatibilities</span>
      </p>
    </div>
    <div class="col">
      <img class="w-25 m-auto" style="display: block;" src="assets/SVG/born2meet.png" alt="">
    </div>
  </div>
</div>

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { SharedModule } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PremiumComponent } from './pages/premium/premium.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SubscribeComponent } from './pages/premium-content/subscribe/subscribe.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule } from '@angular/material/select';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PremiumContentComponent } from './pages/premium-content/premium-content.component';
import { BasketComponent } from './pages/premium-content/basket/basket.component';
import { PackComponent } from './pages/premium-content/pack/pack.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LoadingComponent } from './pages/premium-content/loading/loading.component';
import { ChatListComponent } from './pages/premium-content/chat-list/chat-list.component';
import { TruthComponent } from './pages/premium-content/truth/truth.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiHeaderInterceptor } from './core/services/api-header.interceptor';
import { MatDialogModule } from '@angular/material/dialog';
import { PaymentModalComponent } from './pages/modals/payment-modal/payment-modal.component';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import { LoginComponent } from './pages/premium-content/login/login.component';
import {JwtInterceptor} from './core/services/jwt-interceptor';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule} from 'angularx-social-login';
import {NgxPayPalModule} from 'ngx-paypal';
import { ProfileComponent } from './pages/premium-content/profile/profile.component';
import { AddCreditsModalComponent } from './pages/modals/add-credits-modal/add-credits-modal.component';
import { BasketValidateComponent } from './pages/premium-content/basket-validate/basket-validate.component';
import { ChatMessagesComponent } from './pages/premium-content/chat-messages/chat-messages.component';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PremiumComponent,
    SubscribeComponent,
    PremiumContentComponent,
    BasketComponent,
    PackComponent,
    LoadingComponent,
    ChatListComponent,
    TruthComponent,
    PaymentModalComponent,
    LoginComponent,
    ProfileComponent,
    AddCreditsModalComponent,
    BasketValidateComponent,
    ChatMessagesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    NgbModule,
    BrowserAnimationsModule,
    MatSelectModule,
    CarouselModule,
    MatCheckboxModule,
    MatDialogModule,
    SweetAlert2Module.forRoot(),
    SocialLoginModule,
    NgxPayPalModule,
    NgxSliderModule,
    MatTooltipModule,
    FontAwesomeModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiHeaderInterceptor,
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '463885497303-rtvh6quq4hau1e6sp4p103f352hou8us.apps.googleusercontent.com',
              {
                scope: 'profile email'
              }
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('189460749767352')
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import {CredentialsService} from '../../../core/authentication/credentials.service';
import {User} from '../../../models/user';
import {Router} from '@angular/router';
import {PaymentModalComponent} from '../../modals/payment-modal/payment-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {AddCreditsModalComponent} from '../../modals/add-credits-modal/add-credits-modal.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  user: User;

  constructor(public credentialsService: CredentialsService, private router: Router, private dialog: MatDialog) {
    this.user = this.credentialsService.credentials?.user;
    this.credentialsService.userChangedObservable.subscribe(d => {
      this.user = this.credentialsService.credentials?.user;
    });
  }

  ngOnInit(): void {
  }

  logout(): void {
    this.credentialsService.setCredentials();
    this.router.navigate(['/home']);
  }

  subscribe(): void {
    const dialog = this.dialog.open(AddCreditsModalComponent, {
      width: '1050px',
      panelClass: 'modal-panel'
    });
    dialog.afterClosed().subscribe(value => {
      if (value) {

      }
    });
  }
}

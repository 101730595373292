<section class="container-fluid bg-header py-3 video-background">
  <video autoplay="" loop="" muted="" playsinline="" class="page-left__media" data-id="2" oncanplay="this.play()" onloadedmetadata="this.muted = true">
    <source src="assets/video/home.mp4" type="video/mp4">
  </video>
  <div class="row">
    <div class="col-lg-auto nav-col">
      <div class="nav-content">
        <div class="nav-logo">
          <img src="assets/SVG/born2meet-white.png" alt="">
        </div>
        <div>
          <ul class="link-list">
            <li>
              <a href="#"><i class="fa fa-bars"></i></a>
            </li>
            <li>
              <a [routerLink]="['/home']" fragment="">Accueil</a>
            </li>
            <li>
              <a [routerLink]="['/home']" fragment="ame">La vérité sur votre relation</a>
            </li>
            <li>
              <a [routerLink]="['/premium/subscribe']">Votre âme soeur</a>
            </li>
            <li>
              <a [routerLink]="['/home']" fragment="discover-us">Découvrez-nous</a>
            </li>
            <li>
              <a [routerLink]="['/home']" fragment="tested">On a testé pour vous</a>
            </li><!-- 
            <li>
              <a href="#">Informations</a>
            </li> -->
            <li *ngIf="user" (click)="logout()">
              <a href="#">Déconnexion</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-lg d-flex flex-column justify-content-end">

    </div>
    <div class="col-lg-auto text-white py-3" *ngIf="!user">
      <h4 class="proof-title">PREMIUM</h4>
      <button routerLink="/premium/subscribe" class="primary-button my-2">
        <span class="btn-text">Se Connecter</span>
      </button>
    </div>
    <div class="col-lg-auto py-3 user-item pointer-on-hover" *ngIf="user" routerLink="/premium/profile">
      <img *ngIf="user.profileImage" [src]="user.profileImage"/>
      <img *ngIf="!user.profileImage" [src]="'assets/SVG/upload-avatar.png'"/>
      <div class="">
        <h5 class="d-block">{{user.firstName}} {{user.lastName}}</h5>
        <span *ngIf="user.state == 0">Pas d'abonnement actif</span>
        <span *ngIf="user.state == 1">Abonnement expiré le {{user.packEndDate | date}}</span>
        <span *ngIf="user.state == 2">Abonnement valide jusqu'au {{user.packEndDate | date}}</span>
      </div>
    </div>
  </div>
</section>
<section class="container-fluid px-4 py-2" id="ame">
  <div class="row dark-section h-500 rounded-corners">
    <div class="col-lg-6 middle-border pt-5 pb-3" *ngIf="!user">
      <div class="row pb-3">
        <div class="col">
          <h4 class="proof-title">DÉCOUVREZ <br> VOTRE ÂME SOEUR</h4>
          <h6>VIVEZ UNE RELATION COMPATIBLE ET SAINE AVEC BORN TO MEET</h6>
        </div>
        <div class="col-auto">
          <button class="premium-btn">
            <span class="btn-text">PREMIUM</span>
          </button>
        </div>
      </div>
      <div class="image-banner">
        <ng-container *ngFor="let video of introVideos; let i=index">
          <video *ngIf="i == introVideoIndex" class="intro-video col-12" muted="" oncanplay="this.play()" autoplay="" playsinline="" onloadedmetadata="this.muted = true" (ended)="introVideoEnded()">
            <source src="assets/video/{{video}}"
                    type="video/mp4">
          </video>
        </ng-container>

        <button routerLink="/premium/subscribe" class="sub-btn primary-button">
          <span class="btn-text">Inscription</span>
        </button>
        <button routerLink="/premium/login" class="login-btn primary-button">
          <span class="btn-text">Déjà inscrit</span>
        </button>
      </div>
    </div>
    <div class="col-lg-6 middle-border pt-5 pb-3 pointer-on-hover" routerLink="/premium/profile" *ngIf="user">
      <div class="row pb-3">
        <div class="col">
          <h4 class="proof-title">MON PROFIL</h4>
        </div>
      </div>
      <div class="row ">
        <div class="container user-big-item">
          <img *ngIf="user.profileImage" [src]="user.profileImage"/>
          <img *ngIf="!user.profileImage" [src]="'assets/SVG/upload-avatar.png'"/>
          <div class="">
            <h5 class="d-block">{{user.firstName}} {{user.lastName}}</h5>
            <span *ngIf="user.state == 0">Pas d'abonnement actif</span>
            <span *ngIf="user.state == 1">Abonnement expiré le {{user.packEndDate | date}}</span>
            <span *ngIf="user.state == 2">Abonnement valide jusqu'au {{user.packEndDate | date}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 pt-5 pb-3">
      <div class="row pb-3">
        <div class="col-6">
          <h4 class="proof-title">DÉCOUVREZ<br>
            LA VÉRITÉ<br>
            SUR VOTRE RELATION</h4>
        </div>
        <div class="col-6" style="z-index: 10">
          <div>
            <p class="gender-choice-text">CHOISISSEZ LE GENRE DE VOTRE COUPLE</p>
          </div>
          <div class="d-flex justify-content-between" style="z-index: 10">
            <button class="gender-button"
                    (click)="setCoupleType(1)"
                    [ngClass]="{'selected': getCoupleType() === 1}">
              <svg-icon [svgStyle]="{'width.px': '24'}" src="assets/SVG/avatar-female.svg"></svg-icon>
              <svg-icon [svgStyle]="{'width.px': '24'}" src="assets/SVG/avatar-male.svg"></svg-icon>
            </button>
            <button class="gender-button"
                    (click)="setCoupleType(2)"
                    [ngClass]="{'selected': getCoupleType() === 2}">
              <svg-icon [svgStyle]="{'width.px': '24'}" src="assets/SVG/avatar-female.svg"></svg-icon>
              <svg-icon [svgStyle]="{'width.px': '24'}" src="assets/SVG/avatar-female.svg"></svg-icon>
            </button>
            <button class="gender-button"
                    (click)="setCoupleType(3)"
                    [ngClass]="{'selected': getCoupleType() === 3}">
              <svg-icon [svgStyle]="{'width.px': '24'}" src="assets/SVG/avatar-male.svg"></svg-icon>
              <svg-icon [svgStyle]="{'width.px': '24'}" src="assets/SVG/avatar-male.svg"></svg-icon>
            </button>
          </div>
        </div>
      </div>
      <video class="date-video col-12" [class.d-none]="result != null" autoplay="" loop="" muted="" playsinline="" oncanplay="this.play()" onloadedmetadata="this.muted = true" id="loopVideo" #loopVideo>
        <source src="assets/video/loop.webm"
                type="video/webm">
      </video>
      <video class="date-video col-12" [class.d-none]="result != CompatibilityResults.Compatible" muted="" playsinline="" onloadedmetadata="this.muted = true" id="redLoopVideo" #redLoopVideo (ended)="videoEnded()">
        <source src="assets/video/red.webm"
                type="video/webm">
      </video>
      <video class="date-video col-12" [class.d-none]="result != CompatibilityResults.NonCompatible" muted="" playsinline="" onloadedmetadata="this.muted = true" id="blueLoopVideo" #blueLoopVideo (ended)="videoEnded()">
        <source src="assets/video/blue.webm"
                type="video/webm">
      </video>
      <video class="date-video col-12" [class.d-none]="result != CompatibilityResults.MoyenCompatible" muted="" playsinline="" onloadedmetadata="this.muted = true" id="yellowLoopVideo" #yellowLoopVideo (ended)="videoEnded()">
        <source src="assets/video/yellow.webm"
                type="video/webm">
      </video>
      <div class="row dates" [formGroup]="form" [class.d-none]="loading">
        <div class="col-6">
          <div class="age-circle d-flex justify-content-center align-items-center">
            <div class="date-selection">
              <p *ngIf="form.get('coupleType').value === 1">Femme</p>
              <p *ngIf="form.get('coupleType').value === 2">Vous</p>
              <p *ngIf="form.get('coupleType').value === 3">Vous</p>
              <input formControlName="date1" type="date" max="9999-12-31">
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="age-circle d-flex justify-content-center align-items-center">
            <div class="date-selection">
              <p *ngIf="form.get('coupleType').value === 1">Homme</p>
              <p *ngIf="form.get('coupleType').value === 2">L'autre femme</p>
              <p *ngIf="form.get('coupleType').value === 3">L'autre homme</p>
              <input formControlName="date2" type="date" max="9999-12-31">
            </div>
          </div>
        </div>
      </div>
      <div class="text-center top-view" [class.d-none]="loading">
        <button (click)="submitDates()" class="primary-button top-view pointer-on-hover">
          <span class="btn-text top-view">Valider</span>
        </button>
      </div>
    </div>
  </div>
</section>
<div id="discover-us">
  <app-discover-us></app-discover-us>
</div>
<section class="container-fluid px-4 py-2" id="tested">
  <div class="row">
    <div class="col">
      <h2 class="section-title">WE TESTED FOR YOU</h2>
    </div>
  </div>
  <div class="row dark-section rounded-corners">
    <div class="col-auto py-4 d-flex flex-column justify-content-center">
      <div>
        <div class="prove-it-box d-flex align-items-center justify-content-center">
          <p>WE PROVE IT!</p>
        </div>
      </div>
    </div>
    <div class="col-lg-3 py-4">
      <img class="w-100" src="assets/images/couple-bg.png" alt="">
      <div class="row">
        <div class="col-auto">
          <div class="bottom-arrow"></div>
        </div>
        <div class="col"></div>
        <div class="col-auto">
          <div class="top-arrow"></div>
        </div>
      </div>
      <div class="killed-text py-3 d-flex justify-content-center">
        <p>
          <span class="she-got">SHE GOT</span><br>
          <span class="killed">KILLED!</span>
        </p>
      </div>
    </div>
    <div class="col-lg-5 py-4">
      <img class="w-100" src="assets/images/collage-image.png" alt="">
    </div>
    <div class="col-lg py-4 d-flex flex-column justify-content-center">
      <div>
        <h4 class="proof-title">There is proof?</h4>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi mattis malesuada orci vitae dictum. Nunc hendrerit, massa in lobortis iaculis, velit mauris pretium dolor, quis faucibus urna quam at lorem. Phasellus lacinia augue nec diam iaculis gravida. Sed eget iaculis ligula. Phasellus hendrerit, ex nec consectetur accumsan, libero sapien elementum odio, eget consequat erat neque in dolor. Suspendisse in tincidunt nisl, dictum ullamcorper ipsum. Sed nunc leo, vulputate ac tincidunt id, sodales nec erat. Quisque tempor arcu in nibh pharetra ultricies.
        </p>
      </div>
    </div>
  </div>
</section>

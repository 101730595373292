<section class="container-fluid py-5" id="#discover-us" *ngIf="videos">
  <div class="row">
    <div class="col-lg-4">
      <h2 class="section-title">DISCOVER US</h2>
      <p class="section-paragraph">
        LES RELATIONS AMOUREUSES<br/>
        SONT RÉGIES PAR UN ORDRE<br/>
        COSMIQUE UNIVERSEL<br/>
        CYCLIQUE.<br/>
        SEULE LA CONSCIENCE<br/>
        DE LA PERSONNE DÉCIDE DANS<br/>
        QUEL ORDRE SE POSITIONNER.
      </p>
    </div>
    <div class="col-lg-8">
      <div class="video">
        <div class="form-row">
          <div class="col">
            <iframe height="355" width="100%" [src]="videos[selectedIndex].link" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="col-auto">
            <div class="thumbnail-container" *ngFor="let index of videosIndex">
              <a (click)="selectedIndex = (selectedIndex+index)%(videos.length)">
                <img class="video-thumbnail" [src]="videos[(selectedIndex+index)%(videos.length)].thumbnail" alt="">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

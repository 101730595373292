<div class="loading-header video-background" *ngIf="list == null">
  <video autoplay="" loop="" muted="" playsinline="" class="page-left__media" data-id="2" oncanplay="this.play()">
    <source src="assets/video/BTM_V5.mp4" type="video/mp4">
  </video>
  <div class="loading-text d-flex flex-column align-items-center justify-content-center">
    <p class="saving text-center">En Cours
      <span>•</span>
      <span>•</span>
      <span>•</span>
      <span>•</span>
      <span>•</span>
      <span>•</span>
      <span>•</span></p>
    <img class="born2meet-logo" src="assets/SVG/born2meet.png" alt="">
  </div>
  <div class="text-center">
    <p class="bottom-text">DE RECHERCHE DE VOTRE ÂME SOEUR</p>
  </div>
</div>
<div class="loading-header video-background" *ngIf="list && list.length == 0">
  <video autoplay="" loop="" muted="" playsinline="" class="page-left__media" data-id="2" oncanplay="this.play()">
    <source src="assets/video/BTM_V5.mp4" type="video/mp4">
  </video>
  <div class="text-center">
    <p class="bottom-text">PAS D'ÂMES SOEUR POUR LE MOMENT</p>
  </div>
</div>
<div class="carousel-container video-background" *ngIf="list && list.length > 0">
  <video autoplay="" loop="" muted="" playsinline="" class="page-left__media" data-id="2" oncanplay="this.play()">
    <source src="assets/video/BTM_V5.mp4" type="video/mp4">
  </video>
  <div class="container-fluid">
    <div class="row justify-content-end">
      <div class="col-auto d-flex align-items-center">
        <h4 class="proof-title text-white">PANIER D'AMOUR</h4>
      </div>
      <div class="col-1">
        <div class="number-circle my-2">
          <p style="color: white">{{selectedUsers.length}}</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-1 carousel-nav justify-content-end">
        <button (click)="owlCar.prev()" class="flat-btn">
          <span class="arrow-left">

          </span>
        </button>
      </div>
      <div class="col-10 d-flex flex-column justify-content-center">
        <div>
          <owl-carousel-o [options]="customOptions" #owlCar>
            <ng-template *ngFor="let item of list" carouselSlide>
              <div class="text-center">
                <h4 class="proof-title text-white">
                  {{item.user.firstName.toUpperCase()}}
                </h4>
              </div>
              <div class="d-flex justify-content-center">
                <div class="carousel-element" [class.selected]="isUserSelected(item)">
                  <img [src]="item.user.profileImage || 'assets/images/default-user.jpg'" alt="">
                  <div class="element-row">
                    <div class="image">
                      <div class="d-flex justify-content-center">
                        <img class="detail-image" [src]="item.user.profileImage || 'assets/images/default-user.jpg'" alt="">
                      </div>
                      <button class="btn btn-light add-to-basket my-2" (click)="selectUser(item)">{{isUserSelected(item) ? 'Ajouté' : 'Ajouter au panier'}}</button>
                      <div class="d-flex justify-content-center pointer-on-hover" (click)="selectUser(item)">
                        <img class="heart-circle" src="assets/SVG/heart-circle.png" alt="">
                      </div>
                    </div>
                    <div class="text text-center">
                      <p class="identity">
                        {{item.user.firstName.toUpperCase()}}
                      </p>
                      <p class="identity">
                        {{item.user.country ? item.user.country.name.toUpperCase() : '-'}}
                      </p>
                      <p class="identity">
                        {{item.user.religion.toUpperCase()}}
                      </p>
                      <p class="identity">
                        {{item.user.state == 2 ? 'Disponible' : 'Non Disponible'}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div class="col-1 carousel-nav">
        <button (click)="owlCar.next()" class="flat-btn">
          <span class="arrow-right">

          </span>
        </button>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-2">
        <img src="assets/SVG/love-basket.png" class="love-basket" alt="">
      </div>
      <div class="col-2 d-flex flex-column justify-content-center">
        <p class="basket-text">
          Remplir et valider<br>
le panier d’amour<br>
        </p>
      </div>
    </div>
    <div class="row justify-content-end" [class.d-none]="selectedUsers.length == 0">
      <div class="col">
        <button (click)="validateBasket()" class="accent-btn">
          <span class="btn-text">Valider</span>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="container py-3">
  <div class="row">
    <div class="col-auto" style="display: flex;">
      <img class="m-auto" style="width: 20%; padding-right: 50px;" src="assets/SVG/born2meet.png" alt="">
      <p class="advert-text">
        <span>offre un réseautage d'âmes sœurs déterminées
         </span><br>
        <span>sur la base
          d’études scientifiques sur les compatibilités relationnelles</span>
      </p>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { WeTrustForYouComponent } from './we-trust-for-you/we-trust-for-you.component';
import { DiscoverUsComponent } from './discover-us/discover-us.component';
import {ListDiscoveredAmesComponent} from './list-discovered-ames/list-discovered-ames.component';
import {CarouselModule} from 'ngx-owl-carousel-o';
import { ListAdsComponent } from './list-ads/list-ads.component';
import { ListAmesComponent } from './list-ames/list-ames.component';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [FooterComponent, WeTrustForYouComponent, DiscoverUsComponent, ListDiscoveredAmesComponent, ListAdsComponent, ListAmesComponent],
    exports: [
        FooterComponent,
        DiscoverUsComponent,
        ListDiscoveredAmesComponent,
        ListAdsComponent,
        ListAmesComponent,
    ],
  imports: [
    CommonModule,
    CarouselModule,
    RouterModule
  ]
})
export class SharedModule { }

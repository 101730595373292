import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {CredentialsService} from '../authentication/credentials.service';

@Injectable()
export class ApiHeaderInterceptor implements HttpInterceptor {

  constructor(private credentialsService: CredentialsService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    // if (!request.headers.has('Content-Type') && !request.headers.has('File-Upload')) {
    //   request = request.clone({headers: request.headers.set('Content-Type', 'application/json')});
    //   console.log(12, request);
    // }

    request = request.clone({headers: request.headers.set('Accept', 'application/json')});

    const currentUser = this.credentialsService.credentials;
    if (currentUser && currentUser.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`
        }
      });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(`Error ${error.status}: ${error.statusText}`);
        return throwError(error);
      })
    );
  }
}
